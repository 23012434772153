import axios from "axios";
// import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt/jwt.service";
import { BASE_URL } from "@/core/services/api/constants";
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    // Vue.use(VueAxios, axios);
    window.axios = axios;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return axios.get(resource, {
      params:params
    }).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    if(slug != "")
        slug = "/"+slug;

      axios.defaults.baseURL = BASE_URL;
    return axios.get(`${resource}${slug}`).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    axios.defaults.baseURL = BASE_URL;
    return axios.post(`${resource}`,params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    axios.defaults.baseURL = BASE_URL;
    return axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    axios.defaults.baseURL = BASE_URL;
    return axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    axios.defaults.baseURL = BASE_URL;
    return axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
