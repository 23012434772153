// import store from '@/store/index';
import ApiService from "@/core/services/api/api.service";
import JwtService from "@/core/services/jwt/jwt.service";
// eslint-disable-next-line no-unused-vars
import SessionStorage from "@/core/services/sessionStorage/session.storage.service";

import {
  VERIFICA_AUTENTICACAO, LOGIN, LOGAR, REENVIAR_SENHA_SMS, LOGOUT, CADASTRAR, UPDATE_PASSWORD, PURGE_AUTH, SET_AUTH, SET_PASSWORD, SET_ERROR
} from "@/core/services/store/auth/store/constant";
import moment from "moment";

export default {
  async [LOGIN]({ commit }, credentials) {
    return await ApiService.post("login", credentials).then(({ data }) => {
      // commit(SET_AUTH, {});
      return data;
    }).catch((error) => {
      commit(SET_ERROR, error);
      throw error;
    });
  },
  // eslint-disable-next-line no-unused-vars
  async [LOGAR]({ commit }, dados) {
    JwtService.setExpireToken(moment().format("YYYY-MM-DD HH:mm:ss"))
    commit(SET_AUTH, dados);
    return true;
  },
  [LOGOUT]({ commit }) {
    commit(PURGE_AUTH);
  },
  async [CADASTRAR]({ commit }, params) {
    return await ApiService.post("pessoa/criar", params)
      .then(() => {
        // JwtService.saveToken(this.state.auth.token);
        // SessionStorage.salvarUsuario(this.state.auth.dados);
      })
      .catch((error) => {
        commit(SET_ERROR, error);
        throw error;
      });
  },
  [VERIFICA_AUTENTICACAO]({ commit }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return 0;
    } else {
      commit(PURGE_AUTH);
      return 1;
    }
  },
  [UPDATE_PASSWORD]({ commit }, payload) {
    const password = payload;
    return ApiService.put("password", password).then(({ data }) => {
      commit(SET_PASSWORD, data);
      return data;
    });
  },
  async [REENVIAR_SENHA_SMS]({ commit }, params) {
    return await ApiService.post("gerar-senha", params).then(({ data }) => {
      return data;
    }).catch((error) => {
      commit(SET_ERROR, error);
      throw error;
    });
  },
}