import moment from "moment";

function retirarmascaraDocumentoCNPJ(cpfCnpj) {
    if (cpfCnpj.length == 14) {
        cpfCnpj = cpfCnpj.replaceAll('.', '');
        cpfCnpj = cpfCnpj.replaceAll('-', '');
        return cpfCnpj;
    } else {
        cpfCnpj = cpfCnpj.replaceAll('/', '');
        cpfCnpj = cpfCnpj.replaceAll('.', '');
        return cpfCnpj;
    }
}

function retirarMascaraData() {

}

function diferencaEntreDatas(dataInicio, dataFim, tipoTempo) {

    dataInicio = moment(dataInicio, "YYYY-MM-DD HH:mm:ss");
    dataFim = moment(dataFim, "YYYY-MM-DD HH:mm:ss");
    return dataFim.diff(dataInicio, tipoTempo, false);
}

function adicionarMascaraDocumento(valor) {
    if (valor.length > 11)
        return valor.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    else
        return valor;
}


function mascaraDocumento(valor) {
    if (typeof valor !== "undefined" && valor != null) {
        if (valor.length > 11)
            return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
        else
            return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    } else {
        return valor;
    }

}

function formataTelefone(valor) {
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
    var str = valor.replace(/[^0-9]/g, "").slice(0, 11);
    const result = str.replace(regex, "($1)$2-$3");
    return result;
}

function retirarMascaraCep(cep) {
    if (typeof cep != "undefined" && cep != null) {
        cep = cep.replaceAll('_', '');
        cep = cep.replaceAll('-', '');
    }
    return cep;
}

function retiraMascaraTelefone(telefone) {
    if (typeof telefone != "undefined" && telefone != null) {
        telefone = telefone.replaceAll('(', '');
        telefone = telefone.replaceAll(')', '');
        telefone = telefone.replaceAll('-', '');
        telefone = telefone.replaceAll('_', '');
        telefone = telefone.replaceAll(' ', '');
    }
    return telefone;
}

// funcao abaixo não está sendo usada, pois a mesma não funcionou nas pages e a funcao foi criada dentro de cada modulo
function loadingButton(btnRef, loading, cor = "light") {
    const button = btnRef;
    if (loading) {
        button.disabled = true;
        button.classList.add("spinner", `spinner-${cor}`, "spinner-right");
    } else {
        button.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
        );
        button.disabled = false;
    }
}

function retiraAcentos(str) {
    const com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    const sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let novastr = "";
    for (let i = 0; i < str.length; i++) {
        let troca = false;
        for (let a = 0; a < com_acento.length; a++) {
            if (str.substr(i, 1) == com_acento.substr(a, 1)) {
                novastr += sem_acento.substr(a, 1);
                troca = true;
                break;
            }
        }
        if (troca == false) {
            novastr += str.substr(i, 1);
        }
    }
    return novastr;
}

function validaCep(cep) {
    if (typeof cep === "undefined" || cep == null)
        return false;

    var cep1 = cep.replace(/\D/g, '');
    var validacep = /^[0-9]{8}$/;
    return validacep.test(cep1);
}


function verificaDisponibilidade(nomeCidade, cidadesDisponiveis) {
    // cidadesDisponiveis => bairros do pgp
    // nomeCidade => bairro consultado na api

    let dados = { "cidade": null, "bairro": null };
    cidadesDisponiveis.forEach(cidade => {
        if (retiraAcentos(cidade.nome.toUpperCase()) == retiraAcentos(nomeCidade.toUpperCase())) {
            dados = { "cidade": cidade, "bairro": null };
        }
    });

    return dados;
}

function verificaBairro(nomeBairro, nomesBairros) {
    console.log(nomeBairro);
    console.log(nomesBairros);
}

function formatarDecimal(valor) {
    if (valor == "")
        valor = 0.00;

    if (typeof valor === "string")
        valor = Number(valor);

    return valor.toFixed(2).toLocaleString('pt-BR');
}

function formatarDecimalParaBanco(valor) {
    var valorNovo = valor.replace(/\./g, '');
    return valorNovo.replace(',', '.');
}

function formatarMoedaSemCifrao(valor) {
    return formatarDecimal(valor).replace('.', ',');
}

function formatarMoeda(valor) {
    return "R$ " + formatarDecimal(valor);
}

function IsEmailValido(email, params) {
    if (params.sem_email) {
        return true;
    }

    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

    if (reg.test(email)) {
        email = email.substring(email.indexOf('@'))
        var emails_invalidos = ["@gmail.con", "@gmail.com.br", "@hotmail.com.br", "@hotmai.com", "@htmail.com", "@gmial.com", "@hotmial.com", "@outllook.com", "@gnail.com", "@outloo.com", "@hotmil.com", "@gmal.com", "@gmail.cm", "@htomail.com", "@homail.com", "@gamil.com", "@gmil.com", "@gmai.com", "@hotmailo.com", "@hotnail.com", "@yhaoo.com", "@gamail.com", "@hotamail.com", "@hotamil.com", "@outllok.com", "@hotail.com", "@hormail.com", "@hotmaill.com", "@gmailc.om", "@hotmail.com.com", "@hotmal.com", "@gmaill.com", "@hotmail.om", "@gmail.co", "@hotmaiil.com", "@gotmail.com", "@gmaik.com", "@hhotmail.com", "@hotmaail.com", "@hotmail.co", "@hotimail.com", "@gmail.con", "@homtail.com"];

        var isIncorreto = emails_invalidos.find((el) => el == email);

        if (isIncorreto === undefined) return true;
        return false;
    }
    else {
        return false;
    }
}

function mensagemCumprimento() {
    let d = new Date();
    let hour = d.getHours();

    if (hour < 5) {
        return "Boa Noite";
    }
    else
        if (hour < 8) {
            return "Bom Dia";
        }
        else
            if (hour < 12) {
                return "Bom Dia";
            }
            else
                if (hour < 18) {
                    return "Boa tarde";
                }
                else {
                    return "Boa noite";
                }
}

function formatarDataNascimento(banco, data) {
    if (banco == true && data != "" && typeof data !== "undefined") {
        return moment(data).format("YYYY-MM-DD");
    } else if (banco == false && data != "" && typeof data !== "undefined") {
        return moment(data).format("DD/MM/YYYY");
    } else {
        return null;
    }
}

function dataHoje() {
    return moment().format('DD/MM/YYYY');
}

function dataHoraHoje() {
    return moment().format('YYYY-MM-DD HH:mm:ss');
}

function mascaraCep(cep) {
    return mascaraTudo(cep, '#####-###');
}

function mascaraTudo(value, mask) {
    let i = 0;
    const v = value.toString();

    return mask.replace(/#/g, () => v[i++] || '');
}


export {
    retirarmascaraDocumentoCNPJ, retirarMascaraData, retiraMascaraTelefone, loadingButton, retirarMascaraCep, verificaDisponibilidade, verificaBairro,
    validaCep, formatarDecimal, formatarDecimalParaBanco, formatarMoedaSemCifrao, formatarMoeda, mascaraDocumento, formataTelefone, IsEmailValido,
    mensagemCumprimento, diferencaEntreDatas, adicionarMascaraDocumento, formatarDataNascimento, dataHoje, dataHoraHoje, mascaraCep
};
