import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/index";
import ApiService from "@/core/services/api/api.service";
import VueToastify from "vue-toastify";
import VueTheMask from 'vue-the-mask';
import VueScrollTo from 'vue-scrollto';
import Clipboard from 'v-clipboard'

import Vuelidate from 'vuelidate';
Vue.config.productionTip = false;
// Global 3rd party plugins
import "popper.js";

// "tooltip.js": "^1.3.2",
// import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;

import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
// Vue 3rd party plugins
// import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
// import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
// import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
// import "@/core/plugins/apexcharts";
// import "@/core/plugins/treeselect";

import "@/core/plugins/metronic";

import "@mdi/font/css/materialdesignicons.css";
// import 'tippy.js';
// API service init
ApiService.init();

Vue.use(VueTheMask);
Vue.use(VueScrollTo);
Vue.use(Clipboard);

// Vue.component('input-mask', InputMask);
Vue.use(VueToastify,
  { 
    position: "top-right",
    errorDuration:4000,
    successDuration:2500,
  });

Vue.use(Vuelidate);

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");