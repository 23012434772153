import { PAGAMENTO_PIX_MUTATION, PAGAMENTO_PIX_ERROR_MUTATION,PAGAMENTO_CARTAO_MUTATION,PAGAMENTO_CARTAO_ERROR_MUTATION } from "@/core/services/store/pagamento/store/constant";

export default {
    [PAGAMENTO_PIX_MUTATION](state,dados){
        state.dadosPix = dados;
    },
    [PAGAMENTO_PIX_ERROR_MUTATION](state, error) {
        state.errors = error;
    },

    [PAGAMENTO_CARTAO_MUTATION](state,dados){
        state.dadosCartao = dados;
    },
    [PAGAMENTO_CARTAO_ERROR_MUTATION](state, error) {
        state.errors = error;
    },
}