import ApiService from "@/core/services/api/api.service";

import { PAGAMENTO_CARTAO,PAGAMENTO_PIX,PAGAMENTO_CARTAO_MUTATION,PAGAMENTO_CARTAO_ERROR_MUTATION, PAGAMENTO_PIX_MUTATION,PAGAMENTO_PIX_ERROR_MUTATION } from "@/core/services/store/pagamento/store/constant";

export default {
    async [PAGAMENTO_CARTAO]({commit}, params) {
        return await ApiService.post("app/pagamento", params).then(({data}) => {
            commit(PAGAMENTO_CARTAO_MUTATION, data);
            return data;
        }).catch((error) => {
            commit(PAGAMENTO_CARTAO_ERROR_MUTATION, error);
            throw error;
        });
    },
    async [PAGAMENTO_PIX]({commit}, params) {
        return await ApiService.post("app/pagamento", params).then(({data}) => {
            commit(PAGAMENTO_PIX_MUTATION, data);
            return data;
        }).catch((error) => {
            commit(PAGAMENTO_PIX_ERROR_MUTATION, error);
            throw error;
        });
    },
}