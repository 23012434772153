// action types
export const INICIAR_CONTRATACAO = "iniciarContratacao";
export const BUSCAR_DADOS_CLIENTE = "buscarDadosCliente";
export const BUSCAR_CONTRATACOES_PENDENTES = "buscarContratacao";
export const BUSCAR_CIDADES_DISPONIVEIS = "buscarCidadesDisponiveis";
export const BUSCAR_BAIRROS_DISPONIVEIS = "buscarBairrosDisponiveis";
export const ALTERAR_DADOS_CADASTRO = "alterarDadosCadastroAtivo";
export const BUSCAR_PLANOS_COMBOS = "buscarPlanosCombos";
export const ADICIONAR_SERVICO = "adicionarServico";
export const REMOVER_SERVICO = "removerServico";
export const BUSCAR_DATAS_DISPONIVEIS_PAGAMENTO = "buscarDatasDisponiveisPagamento";
export const BUSCAR_TODOS_SVAS = "buscarTodosSvas";
export const BUSCAR_CEP = "buscarCep";


// mutation types
export const INICIAR_CONTRATACAO_MUTATION = "iniciarContratacao";
export const INICIAR_CONTRATACAO_ERROR_MUTATION = "iniciarContratacaoError";

export const BUSCAR_CONTRATACOES_PENDENTES_MUTATION = "buscarContratacoes";
export const CONTRATACOES_PENDENTES_ERROR_MUTATION = "buscarContratacoesError";

export const BUSCAR_DADOS_CLIENTE_MUTATION = "buscarDadosCliente";
export const BUSCAR_DADOS_CLIENTE_ERROR_MUTATION = "buscarDadosClienteError";

export const BUSCAR_CIDADES_DISPONIVEIS_MUTATION = "buscarCidadeDisponiveis";
export const BUSCAR_CIDADES_DISPONIVEIS_ERROR_MUTATION = "buscarCidadesError";

export const BUSCAR_BAIRROS_DISPONIVEIS_MUTATION = "buscarBairroDisponiveis";
export const BUSCAR_BAIRROS_DISPONIVEIS_ERROR_MUTATION = "buscarBairrosError";

export const ALTERAR_DADOS_CADASTRO_MUTATION = "alterarDadosCadastro";
export const ALTERAR_DADOS_CADASTRO_ERROR_MUTATION = "alterarDadosCadastroError";

export const BUSCAR_PLANOS_COMBOS_MUTATION = "buscarPlanosCombos";
export const BUSCAR_PLANOS_COMBOS_ERROR_MUTATION = "buscarPlanosCombosError";

export const ADICIONAR_SERVICO_MUTATION = "adicionarServico";
export const ADICIONAR_SERVICO_ERROR_MUTATION = "adicionarServicoError";

export const REMOVER_SERVICO_MUTATION = "removerServico";
export const REMOVER_SERVICO_ERROR_MUTATION = "removerServicoError";


