import ApiService from "@/core/services/api/api.service";

import {
  INICIAR_CONTRATACAO,
  INICIAR_CONTRATACAO_MUTATION,
  INICIAR_CONTRATACAO_ERROR_MUTATION,
  BUSCAR_DADOS_CLIENTE,
  BUSCAR_DADOS_CLIENTE_MUTATION,
  BUSCAR_DADOS_CLIENTE_ERROR_MUTATION,
  BUSCAR_CONTRATACOES_PENDENTES,
  BUSCAR_CONTRATACOES_PENDENTES_MUTATION,
  CONTRATACOES_PENDENTES_ERROR_MUTATION,
  BUSCAR_CIDADES_DISPONIVEIS,
  BUSCAR_CIDADES_DISPONIVEIS_MUTATION,
  BUSCAR_CIDADES_DISPONIVEIS_ERROR_MUTATION,
  BUSCAR_BAIRROS_DISPONIVEIS,
  BUSCAR_BAIRROS_DISPONIVEIS_MUTATION,
  BUSCAR_BAIRROS_DISPONIVEIS_ERROR_MUTATION,
  ALTERAR_DADOS_CADASTRO,
  ALTERAR_DADOS_CADASTRO_MUTATION,
  ALTERAR_DADOS_CADASTRO_ERROR_MUTATION,
  BUSCAR_PLANOS_COMBOS,
  BUSCAR_PLANOS_COMBOS_MUTATION,
  BUSCAR_PLANOS_COMBOS_ERROR_MUTATION,
  BUSCAR_DATAS_DISPONIVEIS_PAGAMENTO,
  BUSCAR_TODOS_SVAS,
  BUSCAR_CEP,
  ADICIONAR_SERVICO,
  ADICIONAR_SERVICO_MUTATION,
  ADICIONAR_SERVICO_ERROR_MUTATION,
  REMOVER_SERVICO,
  REMOVER_SERVICO_MUTATION,
  REMOVER_SERVICO_ERROR_MUTATION,
} from "@/core/services/store/home/store/constant";

export default {
  async [INICIAR_CONTRATACAO]({ commit }, params) {
    return await ApiService.post("contratacao/criar", params).then(({ data }) => {
      commit(INICIAR_CONTRATACAO_MUTATION, data);
      return data;
    }).catch((error) => {
      commit(INICIAR_CONTRATACAO_ERROR_MUTATION, error);
      throw error;
    });
  },
  async [BUSCAR_DADOS_CLIENTE]({ commit },) {
    return await ApiService.post("verify_token", []).then(({ data }) => {
      commit(BUSCAR_DADOS_CLIENTE_MUTATION, data);
      return data;
    }).catch((error) => {
      commit(BUSCAR_DADOS_CLIENTE_ERROR_MUTATION, error);
      throw error;
    });
  },
  async [BUSCAR_CONTRATACOES_PENDENTES]({ commit }, params) {
    return await ApiService.get("contratacao/pendentes", params).then(({ data }) => {
      commit(BUSCAR_CONTRATACOES_PENDENTES_MUTATION, data);
      return data;
    }).catch((error) => {
      commit(CONTRATACOES_PENDENTES_ERROR_MUTATION, error);
      throw error;
    });
  },
  // retirar o segundo true, pois o mesmo não é necessario ali
  async [BUSCAR_CIDADES_DISPONIVEIS]({ commit }, params) {
    return await ApiService.get("cidades/disponiveis", params, true).then(({ data }) => {
      commit(BUSCAR_CIDADES_DISPONIVEIS_MUTATION, data);
      return data;
    }).catch((error) => {
      commit(BUSCAR_CIDADES_DISPONIVEIS_ERROR_MUTATION, error);
      throw error;
    });
  },
  // eslint-disable-next-line no-unused-vars
  async [BUSCAR_CEP]({commit}, params) {
    return await ApiService.get(`endereco/consultaCep?cep=${params.cep}`, []).then(({ data }) => {
      return data;
    }).catch((error) => {
      throw error;
    });
  },
  async [BUSCAR_BAIRROS_DISPONIVEIS]({ commit }, params) {
    return await ApiService.get(`contratacao/bairros/${params.id_cidade}`, []).then(({ data }) => {
      commit(BUSCAR_BAIRROS_DISPONIVEIS_MUTATION, data);
      return data;
    }).catch((error) => {
      commit(BUSCAR_BAIRROS_DISPONIVEIS_ERROR_MUTATION, error);
      throw error;
    });
  },
  async [ALTERAR_DADOS_CADASTRO]({ commit }, params) {
    return await ApiService.post("contratacao/alterar", params).then(({ data }) => {
      commit(ALTERAR_DADOS_CADASTRO_MUTATION, data);
      return data;
    }).catch((error) => {
      commit(ALTERAR_DADOS_CADASTRO_ERROR_MUTATION, error);
      throw error;
    });
  },
  async [BUSCAR_PLANOS_COMBOS]({ commit }, params) {
    return await ApiService.get(`contratacao/servico/disponiveis/${params.id_cidade}`, []).then(({ data }) => {
      commit(BUSCAR_PLANOS_COMBOS_MUTATION, data);
      return data;
    }).catch((error) => {
      commit(BUSCAR_PLANOS_COMBOS_ERROR_MUTATION, error);
      throw error;
    });
  },
  async [ADICIONAR_SERVICO]({ commit }, params) {
    return await ApiService.post("contratacao/servico/adicionar", params).then(({ data }) => {
      commit(ADICIONAR_SERVICO_MUTATION, data);
      return data;
    }).catch((error) => {
      commit(ADICIONAR_SERVICO_ERROR_MUTATION, error);
      throw error;
    });
  },
  async [REMOVER_SERVICO]({ commit }, params) {
    return await ApiService.post("contratacao/servico/remover", params).then(({ data }) => {
      commit(REMOVER_SERVICO_MUTATION, data);
      return data;
    }).catch((error) => {
      commit(REMOVER_SERVICO_ERROR_MUTATION, error);
      throw error;
    });
  },
  async [BUSCAR_DATAS_DISPONIVEIS_PAGAMENTO]() {
    return await ApiService.get("parametro?key=dias_pagamento").then(({ data }) => {
      // commit(REMOVER_SERVICO_MUTATION, data);
      return data;
    }).catch((error) => {
      // commit(REMOVER_SERVICO_ERROR_MUTATION, error);
      throw error;
    });
  },
  async [BUSCAR_TODOS_SVAS]() {
    return await ApiService.get("contratacao/servico/valores_agregados").then(({ data }) => {
      // commit(REMOVER_SERVICO_MUTATION, data);
      return data;
    }).catch((error) => {
      // commit(REMOVER_SERVICO_ERROR_MUTATION, error);
      throw error;
    });
  },
}
