import SessionStorage from "@/core/services/sessionStorage/session.storage.service";

import { RESETAR_SENHA_MUTATION, RESETAR_SENHA_ERROR } from "@/core/services/store/resetarSenha/store/constant";

export default {
  // eslint-disable-next-line no-unused-vars
  [RESETAR_SENHA_MUTATION](state, dados) {
    let usuario = SessionStorage.getUsuario();
    usuario.status = 1;
    SessionStorage.salvarUsuario(usuario);
  },
  [RESETAR_SENHA_ERROR](state, error) {
    state.erros = error;
  },
}