// import SessionStorage from "@/core/services/sessionStorage/session.storage.service";

import {
  INICIAR_CONTRATACAO_MUTATION, INICIAR_CONTRATACAO_ERROR_MUTATION,
  BUSCAR_DADOS_CLIENTE_MUTATION,BUSCAR_DADOS_CLIENTE_ERROR_MUTATION,
  BUSCAR_CONTRATACOES_PENDENTES_MUTATION, CONTRATACOES_PENDENTES_ERROR_MUTATION, BUSCAR_CIDADES_DISPONIVEIS_MUTATION,
  BUSCAR_CIDADES_DISPONIVEIS_ERROR_MUTATION, BUSCAR_BAIRROS_DISPONIVEIS_MUTATION, BUSCAR_BAIRROS_DISPONIVEIS_ERROR_MUTATION,
  ALTERAR_DADOS_CADASTRO_MUTATION, ALTERAR_DADOS_CADASTRO_ERROR_MUTATION, BUSCAR_PLANOS_COMBOS_MUTATION, BUSCAR_PLANOS_COMBOS_ERROR_MUTATION,
  ADICIONAR_SERVICO_MUTATION,ADICIONAR_SERVICO_ERROR_MUTATION,REMOVER_SERVICO_MUTATION,REMOVER_SERVICO_ERROR_MUTATION

} from "@/core/services/store/home/store/constant";

export default {

  [INICIAR_CONTRATACAO_MUTATION](state,dados){
    state.contratacoes_pendentes = dados;
  },
  [INICIAR_CONTRATACAO_ERROR_MUTATION](state, error) {
    state.errors = error;
  },

  [BUSCAR_DADOS_CLIENTE_MUTATION](state, dados) {
    state.dadosCliente = dados
  },
  [BUSCAR_DADOS_CLIENTE_ERROR_MUTATION](state, dados) {
    state.errors = dados
  },
  // eslint-disable-next-line no-unused-vars
  [BUSCAR_CONTRATACOES_PENDENTES_MUTATION](state, dados) {
    state.contratacoes_pendentes = dados
  },
  [CONTRATACOES_PENDENTES_ERROR_MUTATION](state, error) {
    state.errors = error;
  },
  [BUSCAR_CIDADES_DISPONIVEIS_MUTATION](state, dados) {
    state.cidades_disponiveis = dados
  },
  [BUSCAR_CIDADES_DISPONIVEIS_ERROR_MUTATION](state, error) {
    state.errors = error;
  },
  [BUSCAR_BAIRROS_DISPONIVEIS_MUTATION](state, dados) {
    state.bairros_disponiveis = dados
  },
  [BUSCAR_BAIRROS_DISPONIVEIS_ERROR_MUTATION](state, error) {
    state.errors = error;
  },
  // eslint-disable-next-line no-unused-vars
  [ALTERAR_DADOS_CADASTRO_MUTATION](state, dados) {
    // state.errors = error;
  },
  [ALTERAR_DADOS_CADASTRO_ERROR_MUTATION](state, error) {
    state.errors = error;
  },
  // eslint-disable-next-line no-unused-vars
  [BUSCAR_PLANOS_COMBOS_MUTATION](state, dados) {
    state.planos_combos = dados;
  },
  [BUSCAR_PLANOS_COMBOS_ERROR_MUTATION](state, error) {
    state.errors = error;
  },
  // eslint-disable-next-line no-unused-vars
  [ADICIONAR_SERVICO_MUTATION](state, dados) {
    state.chips = dados;
  },
  [ADICIONAR_SERVICO_ERROR_MUTATION](state, error) {
    state.errors = error;
  },
  // eslint-disable-next-line no-unused-vars
  [REMOVER_SERVICO_MUTATION](state, dados) {
    state.chips = dados;
  },
  [REMOVER_SERVICO_ERROR_MUTATION](state, error) {
    state.errors = error;
  },
}