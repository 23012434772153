export default {
    salvarUsuario(usuario) {
        return localStorage.setItem('usuario',JSON.stringify(usuario));
    },
    getUsuario() {
        let usuario = localStorage.getItem('usuario');
        if(usuario === null)
            return null;
        else
            return JSON.parse(usuario);
    },
    deleteUsuario() {
        return localStorage.removeItem('usuario');
    },
    updateUsuario(usuario) {
        return localStorage.setItem('usuario',JSON.stringify(usuario));
    },
}