// action types
export const VERIFICA_AUTENTICACAO = "verifyAuth";
export const LOGIN = "login";
export const LOGAR = "logarSemSenha";
export const CHECK = "check";
export const LOGOUT = "logout";
export const CADASTRAR = "register";
export const UPDATE_PASSWORD = "updateUser";
export const REENVIAR_SENHA_SMS = "reenviarSenhaSMS"

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_AUTH_CHECK = "setUserCheck";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";