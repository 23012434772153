
function currentUser(state) {
    return state.dados;
}

function isAuthenticated(state) {
    return state.isAuthenticated;
}

export { currentUser, isAuthenticated }
