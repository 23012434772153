import JwtService from "@/core/services/jwt/jwt.service";
import SessionStorage from "@/core/services/sessionStorage/session.storage.service";
import { PURGE_AUTH, SET_AUTH, SET_PASSWORD, SET_ERROR, SET_AUTH_CHECK} from "@/core/services/store/auth/store/constant";
// import moment from "moment";

export default {
    [SET_ERROR](state, error) {
        state.errors = error;
      },
      [SET_AUTH](state, dados) {
        state.isAuthenticated = true; // seta como autenticado  o usuario
        state.dados = dados; // seta os dados que vieram do login
        state.token = dados.token; // seta o token no estado
        state.errors = {};
        JwtService.saveToken(state.token);
        SessionStorage.salvarUsuario(dados);
      },
      // eslint-disable-next-line no-unused-vars
      [SET_AUTH_CHECK](state,dados){
        state.isAuthenticated = true;
        state.token = dados.token;
        state.dados = dados;
        // JwtService.saveToken(state.token);
        // SessionStorage.salvarUsuario(state.dados);
      },
      [SET_PASSWORD](state, password) {
        state.dados.password = password;
      },
      [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.dados = {};
        state.errors = {};
        JwtService.destroyToken();
        SessionStorage.deleteUsuario();
      }
}