import ApiService from "@/core/services/api/api.service";

import { RESETAR_SENHA, RESETAR_SENHA_MUTATION, RESETAR_SENHA_ERROR } from "@/core/services/store/resetarSenha/store/constant";

export default {
  async [RESETAR_SENHA]({ commit }, params) {
    return await ApiService.post("alterar-senha", params).then(({data}) => {
      commit(RESETAR_SENHA_MUTATION);
      return data;
    }).catch((error) => {
      commit(RESETAR_SENHA_ERROR, error);
      throw error;
    });
  },
}