import state from './state';
import mutations from './mutations';
import actions from './actions';
import * as getters from './getters';

// exporta todas as mutations, state e actions do modulo de login para o index dentro
// da index do login

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true
}

