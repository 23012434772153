import store from '../store';
import { VERIFICA_AUTENTICACAO, SET_AUTH, PURGE_AUTH } from "@/core/services/store/auth/store/constant";
import SessionStorage from "@/core/services/sessionStorage/session.storage.service";
import JwtService from "@/core/services/jwt/jwt.service";
import { diferencaEntreDatas } from "@/core/services/functionsGlobal";
import moment from "moment";

export default async (to, from, next) => {

    if (typeof to.query.plano !== "undefined") {
        localStorage.setItem('plano', to.query.plano.toLowerCase());
    }

    if (typeof to.query.id_usuario_vendedor !== "undefined") {
        localStorage.setItem('id_usuario_vendedor', to.query.id_usuario_vendedor);
    }

    if (typeof to.query.redirecionar !== "undefined") {
        localStorage.setItem('redirecionar', to.query.redirecionar);
    } else {
        localStorage.setItem('redirecionar', 0);
    }

    if (typeof to.query.id_usuario_loja !== "undefined") {
        localStorage.setItem('id_usuario_vendedor', to.query.id_usuario_loja);
    }

    if (typeof to.query.id_origem !== "undefined") {
        localStorage.setItem('id_origem', to.query.id_origem);
        localStorage.setItem('timestamp_id_usuario_vendedor', moment().format('YYYY-MM-DD HH:mm:ss'));
    }


    verificaExpiracaoToken();
    // busca o usuario para colocar no estado
    let usuario = SessionStorage.getUsuario('usuario');

    if (usuario != null) {
        // busca o token para colocar no estado
        // seta os dados no estado
        store.commit(`auth/${SET_AUTH}`, usuario);
    }

    Promise.all([store.dispatch(`auth/${VERIFICA_AUTENTICACAO}`)]).then((retorno) => {
        // retorno 0 => significa que tem o token
        // retorno 1 => significa que não tem token

        // seta o titulo que foi descrito lá no routes
        document.title = to.meta.title;
        if ((retorno == 1 && (to.name == "login" || to.name == "loginSlug")) || (retorno == 1 && to.name == "404")) {
            //se estiver acessando a rota de login ou a 404 sem o token, ele permite normal
            limpezaLocalStorage(to);
            next();
        } else if (retorno == 1) {
            // se está sem token tentando acessar qualquer rota, ele cai na login

            if (to.params?.slug) {
                next({ name: "loginSlug", params: to.params });
            } else {
                next({ name: 'login' });
            }

            
        } else if (retorno == 0 && (to.name == "login" || to.name == "loginSlug")) {
            // se está com token e está acessando a rota de login, ele redireciona para  rota inicial

            if (to.params?.slug) {
                next({ name: "homeSlug", params: to.params });
            } else {
                next({ name: "home" });
            }

        } else if (retorno == 0) {
            // se está com token, ele permite acessar qualquer rota
            next();
        }
    });
    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
}


function verificaExpiracaoToken() {
    let tokenExpire = JwtService.getExpireToken();
    if (tokenExpire != null) {
        if (diferencaEntreDatas(tokenExpire, moment().format("YYYY-MM-DD HH:mm:ss"), 'minutes') > 30) {
            store.commit(`auth/${PURGE_AUTH}`);
        }
    } else {
        JwtService.setExpireToken(moment().format("YYYY-MM-DD HH:mm:ss"));
    }
}

function limpezaLocalStorage(to) {

    if (
        localStorage.getItem('timestamp_id_usuario_vendedor') !== "undefined"
        && localStorage.getItem('timestamp_id_usuario_vendedor') !== null
        && diferencaEntreDatas(localStorage.getItem('timestamp_id_usuario_vendedor'), moment().format("YYYY-MM-DD HH:mm:ss"), 'hours') > 240
    ) {
        if (typeof to.query.plano == "undefined") {
            localStorage.removeItem('plano');
        }
        if (typeof to.query.id_usuario_vendedor == "undefined" && typeof to.query.id_usuario_loja == "undefined") {
            localStorage.removeItem('id_usuario_vendedor');
            localStorage.removeItem('id_usuario_loja');
            localStorage.removeItem('redirecionar');
        }

        if (typeof to.query.id_origem == "undefined") {
            localStorage.removeItem('id_origem');
        }
    }

}
