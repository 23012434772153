import Vue from 'vue'
import VueRouter from 'vue-router'
import beforeEach from './beforeEach';

Vue.use(VueRouter)

const titleDefault = "Nicnet | Checkout";
const routes = [
  {
    path: '/',
    name: 'login',
    meta: {
      title: titleDefault,
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: titleDefault,
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Home.vue')
  },

  {
    path: '/:slug/home',
    name: 'homeSlug',
    meta: {
      title: titleDefault,
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Home.vue')
  },
  {
    path: '/:slug',
    name: 'loginSlug',
    meta: {
      title: titleDefault,
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue')
  },
  {
    path: '/404',
    name: '404',
    // alias: '/404',
    meta: {
      title: 'Nicnet | Pagina não encontrada',
    },
    component: () => import(/* webpackChunkName: "notexisting" */ '../views/404/404.vue')
  },
  {
    path: '*',
    redirect:{
      name:'404'
    }
  }
]

const router = new VueRouter({ routes });
router.beforeEach(beforeEach);
export default router
