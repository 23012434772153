const ID_TOKEN_KEY = "token";
const EXPIRE_TOKEN = "expire_token";

export const getToken = () => { 
  return localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  localStorage.removeItem(ID_TOKEN_KEY);
  localStorage.removeItem(EXPIRE_TOKEN);
};

/// expire token
export const getExpireToken = () => {
  return localStorage.getItem(EXPIRE_TOKEN);
}

export const setExpireToken = expire_date => {
  localStorage.setItem(EXPIRE_TOKEN,expire_date);
}

export default { getToken, saveToken, destroyToken , getExpireToken, setExpireToken};
