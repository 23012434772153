// importa e exporta o s modulos de toda aplicação
// aqui é feita uma juncao de tudo em uma variavel
// para a mesma ser passada para a index dessa mesma pasta


// o @ significa para ir até a src/
// ex: ../../src/views/login/index -> seria mesma coisa
// que a importação abaixo

// import { store as login } from '@/views/login/index';
import { store as auth } from '@/core/services/store/auth/index';
import { store as resetarSenha } from '@/core/services/store/resetarSenha/index';
import { store as home } from '@/core/services/store/home/index';
import { store as pagamento } from '@/core/services/store/pagamento/index';
// import { store as user } from '@/views/user/index';
// import { store as company } from '@/views/company/index';
// import { store as profile } from '@/views/profile/index';
// import { store as attendance } from '@/views/attendances/index';
// import { store as config } from '@/views/config/index';


export default {
    auth,
    resetarSenha,
    home,
    pagamento
    // department,
    // user,
    // company,
    // profile,
    // attendance,
    // config
}
